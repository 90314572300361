<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="10" lg="10" xl="8">
                <div class="plateformes-container">
                    <div class="list-container">
                        <ul class="row-around item-implant">
                             <li :class="{ 'active': button == 1, 'inactive': button != 1 }">
                                <button @click="button = 1"><h3>MiceTrack Pro</h3></button>
                                <div :class="{ 'active': button == 1 }"></div>
                                <p>Température et Comportement</p>
                            </li>
                            <li :class="{ 'active': button == 2, 'inactive': button != 2 }">
                                <button @click="button = 2"><h3>MiceTrack Lite</h3></button>
                                <div :class="{ 'active': button == 2 }"></div>
                                <p>Santé et Comportement</p>
                            </li>
                            <li :class="{ 'active': button == 3, 'inactive': button != 3 }">
                                <button @click="button = 3"><h3>MiceReader</h3></button>
                                <div :class="{ 'active': button == 3 }"></div>
                                <p>Santé et Comportement</p>
                            </li>
                        </ul>
                    </div>
                    <div class="implant-container-inferior row-around">
                        <div class="part-1">
                            <div class="sous-titre"> <h3>{{ sousTitre[button-1] }}</h3></div>
                            <div class="image-implant-container row-around">
                                <img src="../assets/illustrations/MiceTrackPro.png" alt="" :class="{ 'active': button == 1 }">
                                <img src="../assets/illustrations/MiceTrackLite.png" alt="" :class="{ 'active': button == 2 }">
                                <img src="../assets/illustrations/MiceReader.png" alt="" :class="{ 'active': button == 3 }">
                            </div>
                        </div>
                        <div class="part-2">
                                <div class="detail"> 
                                    <details class="detailsElement" open :class="{ 'active': button == 3}">
                                            <summary class="row-between">{{accordionItems[9].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                            <p>{{accordionItems[9].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3}" >
                                        <summary class="row-between">{{accordionItems[0].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[0].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{accordionItems[1].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[1].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{accordionItems[2].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[2].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{accordionItems[3].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[3].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 }">
                                        <summary class="row-between">{{accordionItems[4].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[4].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{accordionItems[5].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[5].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{accordionItems[6].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[6].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{accordionItems[7].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[7].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{accordionItems[8].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[8].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 3}">
                                        <summary class="row-between">{{accordionItems[10].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[10].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active':  button == 3}">
                                        <summary class="row-between">{{accordionItems[11].title}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{accordionItems[11].content}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active':  button == 2}">
                                        <summary class="row-between">{{accordionItems[12].title}}</summary>
                                    </details>
                                </div>

                            <div class="description">
                                <div class="information-implant" :class="{ 'active': button == 1 }">
                                    <h2 class="title-implant">{{implant[0].title}}</h2>
                                    <p>{{implant[0].dimension}}</p>
                                    <p>{{implant[0].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 2 }">
                                    <h2 class="title-implant">{{implant[1].title}}</h2>
                                    <p>{{implant[1].dimension}}</p>
                                    <p>{{implant[1].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 3 }">
                                    <h2 class="title-implant">{{implant[2].title}}</h2>
                                    <p>{{implant[2].dimension}}</p>
                                    <p>{{implant[2].texte}}</p>
                                </div>
                                <div class="button-position">
                                    <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            implant1 : {
                id: 'uududud',
                température : 25,
                acc_x : [0,2,0,2,0,2,0,2,0,41,0,0,45,2,0,5,0,2]
            },  
            button: 1,
            implant: [
                { title: 'MiceTrack Pro', dimension: '( 155 x 20 mm )', texte: 'MiceTrack Pro est notre plateforme composée d’un réseau d’antennes (50x50mm). La Matrice mesure et transmet en temps réel les mouvements et les déplacements de vos animaux, les donnés des capteurs et des implants et pilote les actionneurs placés dans la cage. Le nombre d’antennes et la surface à couvrir est évolutif. En effet, MiceTrack Pro s’adapte aux dimensions que vous souhaitez couvrir (en cm² ou en m²) et accepte tout type de support de vie pour vos animaux (cage, labyrinthes, aquarium… ).' },
                { title: 'MiceTrack Lite', dimension: '( 180 x 20 mm )', texte: 'MiceTrack Lite est notre plateforme low cost specialisée dans le suivi bien-être des rongeurs en animalerie.' },
                { title: 'Mice Reader', dimension: '( 195 x 20 mm )', texte: 'MiceReader accueille 2 souris dans sa cage de 200mm en méthacrylate. La plateforme permet à l’implant de fonctionner à haut débit et de mesurer le poids de la souris' },
            ],
            accordionItems: [
                { title: 'Suivi du bien-être', content: 'Suivi précis de la température des souris afin de mesurer leur bien-être.', active: false },
                { title: 'Suivi du comportement', content: 'Surveillance de l\'activité et de la position des souris en temps réel, permettant une analyse approfondie de leur comportement.', active: false },
                { title: 'Identification passeport', content: 'Lecture du passeport numérique de chaque souris assurant une identification unique.', active: false },
                { title: 'Capacité à suivre jusqu\'à 5 souris', content: 'Possibilité de suivre jusqu\'à 5 souris simultanément, même dans des environnements partagés.', active: false },
                { title: 'Nombre d\'antennes adaptable', content: 'Le système peut être équipé jusqu\'à 40 antennes pour une couverture optimale, permettant des mesures détaillées et précises.', active: false },
                { title: 'Transmission des données au cloud MiceTracking', content: 'Toutes les données sont transmises et stockées automatiquement au cloud MiceTracking, offrant un suivi en temps réel et une analyse approfondie.', active: false },
                { title: 'Suivi 24/24 en temps réel', content: 'Le système fournit un suivi continu, jour et nuit, garantissant une surveillance sans interruption.', active: false },
                { title: 'Suivi environnemental avec capteurs', content: 'Intégration de capteurs pour suivre les conditions environnementales, telles que la température ambiante et l\'humidité.', active: false },
                { title: 'Interaction avec les actionneurs', content: 'Le système offre la possibilité d\'interagir avec les actionneurs, permettant un contrôle supplémentaire et des interventions automatisées.', active: false },
                { title: 'Suivi de la Santé +', content: 'Suivi de la santé des sujets avec la fréquence cardiaque, le taux d\'oxygène, la tension artérielle et l\'analyse chimique grâce à la Spectroscopie', active: false },
                { title: 'Mesures haute fréquence', content: 'Suivi de la frequence cardiaque avec la ppg spectrométrie.', active: false },
                { title: 'Capacité à suivre jusqu\'à 2 souris', content: 'Possibilité de suivre jusqu\'à 2 souris simultanément', active: false },
                { title: 'Low-Cost', content: '', active: false },
            ],
            sousTitre: [ 'Température et Comportement', 'Santé et Comportement', 'Santé et Comportement' ],
        };
    },
    methods: {
         toggleAccordion(index) {
            this.$set(this.accordionItems, index, {
                ...this.accordionItems[index],
                active: !this.accordionItems[index].active,
            });
        }
    },
};
</script>

<style scoped>

ul {
    padding-left: 0px !important;
}

.plateformes-container {
    border-radius: 25px;
    background-color: var(--white);
    padding: 20px;
    min-width: fit-content;
}

.list-container {
    margin-top: 25px;
    position: sticky;
    top: 0px;
    background-color: var(--white);
    z-index: 1000;
}

.item-implant li {
    text-align: center;
}

.item-implant button:hover {
    opacity: 0.7;
}

.item-implant li div {
    width: 0px;
    height: 3px;
    background-color: #000;
    transition: width 0.5s ease; /* Animation sur la hauteur */
}

.item-implant li div.active {
    width: 100%; /* Hauteur finale lors de l'animation */
}

.implant-container-inferior {
    margin-top: 55px;
    height: fit-content;
}
.part-1 {
    width: 45%;
}

.part-2 {
    width: 45%;
}

.tilte-part {
    margin-bottom: 15px !important;
}

.image-implant-container {
    position: sticky;
    top: 120px;
    text-align: center;
    align-content: center;
}

.image-implant-container img {
    position: relative;
    z-index: 2;
    width: 80%;
    display: none;
}

.image-implant-container img.active {
    display: block;
}

.list-capteurs {
    padding-left: 0px !important;
    list-style: circle;
    width: 90%;
}

.background-capteurs {
    border-radius: 20px;
    border: solid 1px #333;
    width: 100%;
    min-width: fit-content;
    padding: 20px;
}

.button-position {
    display: flex;
    justify-content: right;
    margin-top: 50px;
}

.list-capteurs li {
    display: none;
    justify-content: left;
    align-items: center;
    opacity: 0;
    transform: translateX(-20px); /* Positionne les éléments légèrement hors de l'écran à gauche */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.list-capteurs li.activeico {
    opacity: 1;
    display: flex;
    transform: translateX(0);
}

.list-capteurs li img {
    width: 62px;
    height: 50px;
}

.list-capteurs li p {
    margin-top: 16px;
    margin-left: 10px;
    font-family: 'roboto', light;
    font-size: 16px;
}

.information-implant {
    margin-top: 30px;
    animation: fadeInUp 0.5s ease-in-out forwards;
    display: none;
}

.information-implant.active {
    display: block;
}

.sous-titre{ 
    display: none;
}

.detail div {
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
}

summary{
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  list-style: none;
  padding-bottom: 20px;
  padding-top: 10px;
}
details {
    overflow: hidden;
    cursor: pointer;
    border-bottom: solid 1px var(--noirdoux);
}
details p {
  animation: fadeInlow 0.3s ease-in-out;
  animation-play-state: paused;
}

details[open] p {
  animation-play-state: running;
}
details .ouvert {
  display: none;
}
details .fermé {
  display: block;
}
details[open] .ouvert {
  display: block;
}
details[open] .fermé {
  display: none;
}

.detailsElement{
    width: 0;
    height: 0;
}
.detailsElement.active{
    width: auto;
    height: auto;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInlow {
    0% {
        opacity: 0.5;
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}


@media (max-width: 960px) {
    .implant-container {
        background-color: transparent;
        border: none;
        padding: 0;
    }
    .implant-container-inferior {
        margin-top: 50px;
    }
    
    .item-implant li {
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
    .item-implant li p{
        display: none;
    }
    .item-implant li.inactive {
        transform: scale(0.7); /* Réduit la taille des éléments inactifs */
        opacity: 0.5; /* Rend les éléments inactifs moins opaques */
    }

    .item-implant li.active {
        transform: scale(1); /* Taille normale de l'élément actif */
        opacity: 1; /* Pleine opacité pour l'élément actif */
    }

    .item-implant button {
        font-weight: 400;
        font-size: 28px;
    }
    .sous-titre{ 
        display: block;
        text-align: center
    }
    summary{
        font-size: 20px;
    }
    .item-implant li.active {
        display: block;
    }
    .implant-container-inferior {
        display: block;
    }
    .part-2,
    .part-1 {
        width: 100%;
    }

    .capteurs {
        margin-top: 50px;
    }
    .description {
        margin-top: 50px;
    }
    .background-capteurs {
        background-color: #fff;
    }
    .image-implant-container {
        margin-top: 50px;
    }
    .image-implant-container img {
        width: 80%;
    }
    .tilte-part {
        text-align: center;
    }

    .button-position {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .list-capteurs {
        width: 100%;
    }
}

</style>