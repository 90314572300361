<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="10" md="5" lg="5" xl="4" style="align-content: center;">
                <h2>Nos plateformes sont entièrement personnalisables !</h2>
                <p class="mt30">
                    Chez MiceTracking, nous comprenons que chaque structure a des besoins uniques, que ce soit pour des projets de recherche ou pour de grandes animaleries. C’est pourquoi nos plateformes sont personnalisables selon vos exigences : taille, nombre d’antennes, forme (rectangulaire, ronde, etc.), tout est personnifiables pour s’adapter à vos besoins et objectifs.
                </p>
            </v-col>
            <v-col cols="12" sm="8" md="5" lg="5" xl="4" class="img-container">
                <div class="carrousel-container">
                <v-carousel cycle height="600" show-arrows-on-hover>
                    <v-carousel-item v-for="(item, i) in carouselItems" :key="i" :src="item.src" :alt="item.alt"></v-carousel-item>
                </v-carousel>
                </div>
                
            </v-col>
        </v-row>
    </div>
</template>

<style>
.carrousel-container {
  border-radius: 40px; /* Ajustez la valeur selon vos préférences */
  overflow: hidden; /* Assure que le contenu respecte les bords arrondis */
}
</style>


<script>

export default{
    data() {
        return {
            carouselItems: [
                { src: require('@/images/aquarium.jpg'), alt: 'Aquarium' },
                { src: require('@/images/labyrinthe-en-Y.jpg'), alt: 'Labyrinthe en Y' },
                { src: require('@/images/labyrinthe.jpg'), alt: 'Labyrinthe' },
                { src: require('@/images/cage_souris_verre.jpg'), alt: 'Cage souris en verre' },
                { src: require('@/images/cage-souris.jpg'), alt: 'Cage Animalerie' },
                { src: require('@/images/actionneur-roue.jpg'), alt: 'Roue d\'exercice' },
            ]
        };
    }
}
    </script>